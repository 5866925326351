import websiteImg1 from '../assets/Screenshot 2024-07-03 155042.png'
import websiteImg2 from '../assets/Screenshot 2024-07-03 160558.png'
import websiteImg3 from '../assets/Screenshot 2024-07-03 160801.png'
export default function Projects()  {
  return (
  
    <section id="projects" className='flex flex-col md:flex-col bg-primary px-5'>
    <div className="text-4xl text-white text-center mt-4 font-bold mb-2 md:text-4xl md:mb-4 ">Projects</div>
      <div className="flex flex-wrap justify-center gap-8 mt-8">
        <div className="flex flex-col bg-secondary items-center w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 border border-gray-300 rounded-lg p-4 mb-8">
          <div className="w-full h-50 overflow-hidden">
            <img src={websiteImg1} alt="" className="w-full h-full object-cover rounded-t-lg" />
          </div>
          <div className="text-xl text-white font-bold my-2">Shopzilla-E-commerce Website</div>
          <div className="text-sm text-white text-center  my-2">An e-commerce website designed and developed to provide a seamless and engaging shopping experience, featuring a responsive design, secure payment integration, and user-friendly navigation.</div>
          <div className="flex justify-between gap-12 mt-auto w-auto">
            <a href='https://github.com/akashn2002/shop-zilla.git' target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">Code</a>
            <a href='https://creative-syrniki-d0f9ed.netlify.app'target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">View</a>
          </div>
        </div>
        <div className="flex flex-col bg-secondary items-center w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 border border-gray-300 rounded-lg p-4 mb-8">
          <div className="w-full h-50 overflow-hidden">
            <img src={websiteImg2} alt="" className="w-full h-full object-cover rounded-t-lg" />
          </div>
          <div className="text-xl text-white font-bold my-2">AI Chat Bot</div>
          <div className="text-sm text-white text-center  my-2 ">A Gemini clone developed to replicate the minimalist and privacy-focused browsing experience, featuring a lightweight design, enhanced security measures, and a streamlined user interface.</div>
          <div className="flex justify-between gap-12 mt-auto w-auto">
            <a href='https://github.com/akashn2002/chat-bot.git'target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">Code</a>
            <a href='https://sprightly-cucurucho-8263fc.netlify.app' target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">View</a>
          </div>
        </div>
        <div className="flex flex-col bg-secondary items-center w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 border border-gray-300 rounded-lg p-4 mb-8">
          <div className="w-full h-50 overflow-hidden">
            <img src={websiteImg3} alt="" className="w-full h-full object-cover rounded-t-lg" />
          </div>
          <div className="text-xl text-white font-bold my-2">Weather App</div>
          <div className="text-sm text-white text-center my-2">A weather app clone designed to deliver accurate and real-time weather information, featuring a clean and responsive interface, interactive forecasts, and integration with the OpenWeatherMap API.</div>
          <div className="flex justify-between gap-12 mt-auto w-auto">
            <a href='https://github.com/akashn2002/weather-app.git'target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">Code</a>
            <a href='https://stately-entremet-af5845.netlify.app' target='_blank' className="bg-blue-700 text-white my-4 px-8 py-2 rounded-md">View</a>
          </div>
        </div>
      </div>
      </section>

  );
};

