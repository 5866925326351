import AboutImg from '../assets/about.png';

export default function About () {
    return <section  className='flex flex-col   md:flex-row bg-primary py-20 px-5' id='about'>
        <div className='py-5 md:w-1/2'>
            <img src={AboutImg}  />
        </div>
        <div className='md:w-1/2 flex justify-center'>
        <div className='flex flex-col justify-center'>
        <h1 className='text-4xl text-white border-b-4 mb-5 w-[170px] font-bold'>About Me</h1>
        <p className='text-white  '>I am a Passionate Front-End Web Developer | Transforming Ideas into Seamless and 
        Visually Stunning Web Solutions</p>
        </div>
           
        </div>
    </section>
}
