
import { FaGitAlt, FaHtml5, } from "react-icons/fa";
 import { TbBrandRedux } from "react-icons/tb";
 import { FaCss3 } from "react-icons/fa";
 import { RiJavascriptFill, RiTailwindCssFill } from "react-icons/ri";
 import { FaReact } from "react-icons/fa";
 import { SiMui } from "react-icons/si";
 import { FaBootstrap } from "react-icons/fa";

export default function Skills() {
    return (
        <section id="skills" className="p-4 bg-secondary py-10">
            <div className="text-4xl text-white text-center font-bold mb-16">My Skills</div>
            <div className="grid grid-cols-3 gap-4 mx-auto max-w-screen-lg">
                <SkillItem icon={<FaHtml5 className="text-5xl md:text-8xl text-orange-500 border border-solid border-white rounded-full p-2 mb-2"/>} name="HTML5" />
                <SkillItem icon={<FaCss3 className="text-5xl md:text-8xl text-blue-500 border border-solid border-white rounded-full p-2 mb-2"/>} name="CSS3" />
                <SkillItem icon={<RiJavascriptFill className="text-5xl md:text-8xl text-yellow-500 border border-solid border-white rounded-full p-2 mb-2"/>} name="JavaScript" />
                <SkillItem icon={<FaReact className="text-5xl md:text-8xl text-blue-400 border border-solid border-white rounded-full p-2 mb-2"/>} name="React" />
                <SkillItem icon={<SiMui className="text-5xl md:text-8xl text-blue-700 border border-solid border-white rounded-full p-2 mb-2"/>} name="Material-UI" />
                <SkillItem icon={<FaBootstrap className="text-5xl md:text-8xl text-purple-600 border border-solid border-white rounded-full p-2 mb-2"/>} name="Bootstrap" />
                <SkillItem icon={<RiTailwindCssFill className="text-5xl md:text-8xl text-cyan-500 border border-solid border-white rounded-full p-2 mb-2"/>} name="Tailwind CSS" />
                <SkillItem icon={<FaGitAlt className="text-5xl md:text-8xl text-red-600 border border-solid border-white rounded-full p-2 mb-2"/>} name="Git" />
                <SkillItem icon={<TbBrandRedux className="text-5xl md:text-8xl text-purple-600 border border-solid border-white rounded-full p-2 mb-2"/>} name="Redux" />
            </div>
        </section>
    );
}

function SkillItem({ icon, name }) {
    return (
        <div className="flex flex-col items-center justify-center">
            {icon}
            <span className="text-white text-center mt-2">{name}</span>
        </div>
    );
}
