import HeroImg from '../assets/hero.png';

import { AiFillGithub, AiOutlineLinkedin} from "react-icons/ai";

export default function Hero() {
    return<section className='flex flex-col md:flex-row px-5 py-32 bg-secondary justify-center'>
         <div className='md:w-1/2 flex flex-col'>
            <h1 className=' text-white text-6xl '>Hi, <br/> Im <span className='text-black'>AKASH </span >N
                <p className='text-3xl py-5'>Im a Front-End Developer</p>
               
                </h1>
       
        <div className='flex py-10' >
            <a href="https://www.linkedin.com/in/akash-n-a6a84a311/" target='_blank' className=' pr-5  hover:text-white'><AiOutlineLinkedin size={40}/></a>
            <a href='https://github.com/akashn2002' target='_blank' className='hover:text-white '><AiFillGithub size={40} /></a>
        </div>
        </div>
        <img className='md:w-1/3' src={HeroImg}/>
    </section>
}