import resume from '../assets/Resume.pdf'
export default function Contact () {

    const config = {
        email : 'akashnagesh10@gmail.com',
        phone: '+8618333686'
    }

    return <section id='contact' className='flex flex-col bg-secondary px-5 py-4 text-white'>
        <div className='flex flex-col items-center'>
           
            <h1 className='text-4xl font-bold'>Contact</h1>
            <p className='pb-4 text-sm pt-3'>If you want to discuss more in detail, please contact me</p>
            <p className='py-2'><span className='font-bold'>Email :</span> {config.email}</p>
            <p className='py-2 mb-8'><span className='font-bold'>Phone :</span> {config.phone}</p>
            <a className="py-2 bg-black px-5 font-bold hover:border-2 border-white rounded " href={resume} download="Resume" >Download Resume</a>
        </div>
    </section>
}